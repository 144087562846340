import getConfig from 'next/config';
var imageWidth = {
  carousel: '350x300',
  horizontal: '229x225',
  'horizontal-highlight': '430x300',
  thumbnail: '77x77'
};
var highlightVariations = ['horizontal-highlight', 'carousel'];
var parseBlogLuContent = function parseBlogLuContent() {
  var recommendationsList = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var variation = arguments.length > 1 ? arguments[1] : undefined;
  var _getConfig = getConfig(),
    config = _getConfig.publicRuntimeConfig;
  return recommendationsList.map(function (recItem) {
    var _recItem$posts = recItem.posts,
      posts = _recItem$posts === void 0 ? [] : _recItem$posts;
    return posts.map(function (item, index) {
      var _image$urls, _config$blog, _config$blog2;
      var category = item.category,
        subcategory = item.subcategory,
        id = item.id,
        _item$title = item.title,
        title = _item$title === void 0 ? '' : _item$title,
        subtitle = item.subtitle,
        image = item.image,
        _item$content = item.content,
        content = _item$content === void 0 ? '' : _item$content,
        slug = item.slug;
      var size = highlightVariations.includes(variation) && index ? imageWidth.thumbnail : imageWidth[variation] || imageWidth.thumbnail;
      var imageUrl = "".concat(image == null ? void 0 : (_image$urls = image.urls) == null ? void 0 : _image$urls["default"], "?ims=").concat(size);
      return {
        category: category,
        html: content,
        id: id,
        image: imageUrl,
        subcategory: subcategory,
        subtitle: subtitle,
        title: title,
        type: 'article',
        url: "".concat(config == null ? void 0 : (_config$blog = config.blog) == null ? void 0 : _config$blog.domain, "/blog-da-lu/c/").concat(slug, "/").concat(id, "/"),
        urlPostSubcategory: "".concat(config == null ? void 0 : (_config$blog2 = config.blog) == null ? void 0 : _config$blog2.domain, "/blog-da-lu/s/").concat(category == null ? void 0 : category.categoryId, "/").concat(subcategory == null ? void 0 : subcategory.categoryId, "/").concat(subcategory == null ? void 0 : subcategory.slug, "/").concat(subcategory == null ? void 0 : subcategory.id, "/").toLowerCase()
      };
    });
  });
};
export default parseBlogLuContent;