import { cookiesConstants, getCookie, setCookie } from './cookies';
var CUSTOMER_ID = cookiesConstants.CUSTOMER_ID,
  CUSTOMER_TEMP_ID = cookiesConstants.CUSTOMER_TEMP_ID,
  ML2_TID = cookiesConstants.ML2_TID,
  ML2_SID_C = cookiesConstants.ML2_SID_C;
var generateUUID = function generateUUID() {
  var _window, _window$crypto;
  return (_window = window) == null ? void 0 : (_window$crypto = _window.crypto) == null ? void 0 : _window$crypto.randomUUID == null ? void 0 : _window$crypto.randomUUID();
};
var getTrackId = function getTrackId() {
  return getCookie(ML2_TID);
};
var getCustomerId = function getCustomerId() {
  return getCookie(CUSTOMER_ID);
};
var getUserCookie = function getUserCookie() {
  return getCookie(ML2_SID_C);
};

var generateTrackId = function generateTrackId(domain) {
  var trackId = getTrackId();
  if (!trackId) {
    trackId = generateUUID();
    setCookie({
      domain: domain,
      name: ML2_TID,
      value: trackId
    });
  }
  return trackId;
};

var generateCustomerId = function generateCustomerId(domain) {
  var customerId = getCustomerId();
  var isTempCookie = customerId == null ? void 0 : customerId.includes('temp_');
  var userCookie = getUserCookie();
  if (!isTempCookie && userCookie && (userCookie == null ? void 0 : userCookie.id) === customerId) {
    return;
  }
  if (!customerId && !(userCookie == null ? void 0 : userCookie.id)) {
    var customerUUID = generateUUID();
    setCookie({
      domain: domain,
      name: CUSTOMER_ID,
      value: "temp_".concat(customerUUID)
    });
    setCookie({
      domain: domain,
      name: CUSTOMER_TEMP_ID,
      value: 1
    });
  } else if (userCookie == null ? void 0 : userCookie.id) {
    setCookie({
      domain: domain,
      name: CUSTOMER_ID,
      value: userCookie == null ? void 0 : userCookie.id
    });
    setCookie({
      domain: domain,
      name: CUSTOMER_TEMP_ID,
      value: 0
    });
  }
};
export default {
  generateCustomerId: generateCustomerId,
  generateTrackId: generateTrackId,
  generateUUID: generateUUID,
  getCustomerId: getCustomerId,
  getTrackId: getTrackId,
  getUserCookie: getUserCookie
};